<template>
    <div class="container h-screen flex justify-center items-center mx-auto">
        <div class=" shadow-xl  w-8/12 myCard relative">
            <div class="container mycontent flex flex-col justify-center items-center">
                <h1 class="text-center text-3xl  font-normal text-blue-600">How &nbsp;would &nbsp;you &nbsp;rate &nbsp;your <br> today's &nbsp;experience? </h1>
                <div class="flex mt-14">
                    <div class="w-28 h-28">
                        <button @click="bad">
                            <img src="../assets/badnews.png" alt="bad">
                            <p class="text-sm mt-1 text-slate-500">Bad</p>
                        </button>
                    </div>
                    <div class="w-28 h-28 mx-14">
                        <button @click="normal">
                            <img src="../assets/normalnew.png" alt="normal">
                            <p class="text-sm mt-1 text-slate-500">It's okay</p>
                        </button>
                    </div>
                    <div class="w-28 h-28">
                        <button @click="thanks">
                            <img src="../assets/goodnews.png" alt="good">
                            <p class="text-sm mt-1 text-slate-500">Thanks</p>
                        </button>
                    </div>
                </div>
            </div>
            <div class="mac flex">
                <div class="w-4 h-4 rounded-full" style="background-color:#FF605C;"></div>
                <div class="w-4 h-4 rounded-full bg-red-200 mx-1" style="background-color: #FFBD44;"></div>
                <div class="w-4 h-4 rounded-full bg-red-200" style="background-color: #00CA4E;"></div>
            </div>
            
            <div class="copyright">
                <p class="text-blue-500 text-center text-lg"><span class="font-bold">SENTUH</span> <i>research & development</i></p>
            </div>
        </div>
    </div>

    <!-- modal -->
    <!-- Good Response -->
    <div id="open-modaladd" class="modal-window">
      <div class="modal-content bg-gradient-to-r from-cyan-500 to-blue-500">
        <div class="content-text container myModalContent flex flex-col justify-center items-center">
            <div v-if="!sayThanks">
                <h1 class="text-center text-3xl  font-normal text-white">wow ,  <span class="text-blue-900 font-medium">we're glad to hear that</span>, we'll take care of it as best we can</h1>
            </div>
            <div v-if="sayThanks">
                <h1 class="text-center text-5xl  font-normal text-white font-bold">Thanks</h1>
                <h1 class="mt-14 text-center text-lg text-white font-normal text-black">This Pop up will close Automatically in 1 minutes</h1>
            </div>
        </div>
      </div>
      <div style="position: absolute; right: 0; bottom: 0;opacity: 0.1;">
          <img src="../assets/samsat.png" alt="">
      </div>
    </div>

    <!-- Normal Response -->
    <div id="open-normalresponse" class="modal-window">
      <div class="modal-content bg-gradient-to-r from-cyan-500 to-blue-500">
        <div class="content-text container myModalContent flex flex-col justify-center items-center">
            <div v-if="!sayThanks">
                <h1 class="text-center text-3xl  font-normal text-white">wow ,  <span class="text-blue-900 font-medium">we're glad to hear that</span>, we'll take care of it as best we can</h1>
            </div>
            <div v-if="sayThanks">
                <h1 class="text-center text-5xl  font-normal text-white font-bold">Thanks</h1>
                <h1 class="mt-14 text-center text-lg text-white font-normal text-black">This Pop up will close Automatically in 1 minutes</h1>
            </div>
        </div>
      </div>
      <div style="position: absolute; right: 0; bottom: 0;opacity: 0.1;">
          <img src="../assets/samsat.png" alt="">
      </div>
    </div>

    <!-- Bad Response -->
    <div id="open-badresponse" class="modal-window">
      <div class="modal-content bg-gradient-to-r from-cyan-500 to-blue-500">
        <div class="content-text container myModalContent flex flex-col justify-center items-center">
            <div v-if="!sayThanks">
                <h1 class="text-center text-3xl  font-normal text-white">Hai ,  <span class="text-blue-900 font-medium">we're sorry :( </span>, we promise to be better it in the future</h1>
            </div>
            <div v-if="sayThanks">
                <h1 class="text-center text-5xl  font-normal text-white font-bold">Thanks</h1>
                <h1 class="mt-14 text-center text-lg text-white font-normal text-black">This Pop up will close Automatically in 1 minutes</h1>
            </div>
        </div>
      </div>
      <div style="position: absolute; right: 0; bottom: 0;opacity: 0.1;">
          <img src="../assets/samsat.png" alt="">
      </div>
    </div>
</template>

<script setup>
import { ref} from 'vue'
import axios from 'axios'
import moment from 'moment'
let sayThanks = ref(false)

const thanks = () => {
    window.location.href='#open-modaladd';

    let payload = {
        name: 'baik',
        date: moment(new Date()).format('YYYY-MM-DD')
    }

    axios.post('http://devsign.sentuhdigital.id/api/voutes/store', payload)
        .then(({data}) => {
            console.log(data,'------------>');
        })
        .catch((err) => {
            console.log(err);
        })

    setTimeout(() => {
    sayThanks.value = true;
    }, 5000);

    setTimeout(() => {
    window.location.href='#';
    }, 10000);

    setTimeout(() => {
        sayThanks.value = false;
    }, 11000);
}

const normal = () => {
    window.location.href='#open-normalresponse';

    let payload = {
        name: 'normal',
        date: moment(new Date()).format('YYYY-MM-DD')
    }


    axios.post('http://devsign.sentuhdigital.id/api/voutes/store', payload)
        .then(({data}) => {
            console.log(data,'------------>');
        })
        .catch((err) => {
            console.log(err);
        })

    setTimeout(() => {
    sayThanks.value = true;
    }, 5000);

    setTimeout(() => {
    window.location.href='#';
    }, 10000);

    setTimeout(() => {
        sayThanks.value = false;
    }, 11000);
}

const bad = () => {
    window.location.href='#open-badresponse';

    let payload = {
        name: 'buruk',
        date: moment(new Date()).format('YYYY-MM-DD')
    }


    axios.post('http://devsign.sentuhdigital.id/api/voutes/store', payload)
        .then(({data}) => {
            console.log(data,'------------>');
        })
        .catch((err) => {
            console.log(err);
        })

    setTimeout(() => {
    sayThanks.value = true;
    }, 5000);

    setTimeout(() => {
    window.location.href='#';
    }, 10000);

    setTimeout(() => {
        sayThanks.value = false;
    }, 11000);
}

</script>

<style>
 .myCard {
    min-height: 80vh;
    border-radius: 25px;

    /* backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(17, 25, 40, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125); */

    backdrop-filter: blur(1px) saturate(180%);
    -webkit-backdrop-filter: blur(1px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.125);

    /* background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.3); */
 }

 .mycontent {
    min-height: 70vh;
 }

 .clock {
    position: absolute;
    left: 50px;
    bottom: 50px;
 }
 .copyright {
 }
 .mac {
    position: absolute;
    left: 50px;
    top: 50px;
 }

 .modal-window {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.70);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}
.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.modal-window > div {
  width: 800px;
  height: 500px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
}

.myModalContent {
    min-height: 400px;
}

.modal-window header {
  font-weight: bold;
}
/* .modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
} */
.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}
.modal-close:hover {
  color: black;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  padding-top: 15%;
}

/* Modal Content */
.modal-content {
  background-color: #ffffff;
  margin: auto;
  padding-top: 60px;
  /* border: 1px solid #888; */
  width: 555px;
  height: 40%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}
</style>